// Import global CSS
import '/public/css/main.css'
import '/public/css/alice-carousel.css'
import '/public/css/react-calendar.css'
import '/public/css/react-date-time.css'
import config from './config.client'

/* Import React Libraries */
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'

// import { Integrations } from '@sentry/tracing' // disabled temporarily
import { Provider } from 'react-redux'
import SocketProvider from '../universal/v2/contexts/SocketProvider'
import GlobalSocketListeners from '../universal/v2/contexts/GlobalSocketListeners'

/* Import our store configuration */
import configureStore from '../universal/store'
import { history } from '../universal/history'
import { Routes, Route } from 'react-router-dom'
import CustomRouter from './CustomRouter'
/* Import Root Component */
import Root from '../universal/containers/Root'
// const Root =  loadable(() => import(/* webpackPrefetch: true */ '../universal/containers/Root'))

// React meta tags
import { HelmetProvider } from 'react-helmet-async'

// Loadable components
import { loadableReady } from '@loadable/component'

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__

// ServiceWorker Workbox library
import { Workbox } from 'workbox-window'
import { clientsClaim } from 'workbox-core'

// Import Hooks
import useAppInstalledEvent from './../universal/v2/hooks/useAppInstalledEvent'

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__

// Create Redux store with initial state
const store = configureStore(preloadedState)
// console.log("STORE FROM STATE", store.getState())

/* Initialize Sentry, before initializing React */
Sentry.init({
	dsn: 'https://9136c62a73e04645b5d8ab48f1052db3@o393450.ingest.sentry.io/5242581',
	debug: process.env.NODE_ENV === 'development', // enables additional debug output in dev
	environment: config.environment,
	normalizeDepth: 6, // increased to see deeper in redux state context
	release: process.env.NODE_ENV === 'development' ? 'dev' : undefined, // don't need this, webpack plughin takes care fo this
	// beforeSend(event, hint) {
	// 	// only use for while debugging :
	// 	// console.error('*************\n\n SENTRY beforeSend listener\n EVENT : ', event, ' \n HINT : ', hint)

	// 	// Important : do not show dialog for ChunkLoadError !!!
	// 	const isChunkLoadError = hint?.originalException?.name === 'ChunkLoadError'
	// 	// do not show dialog for stripe sdk load error
	// 	const isStripeSdkError = hint?.originalException?.message === 'Failed to load Stripe.js'
	// 	// do not show dialog for google pagead errors
	// 	const isGooglePageAddError = hint?.originalException?.message.includes('pagead/viewthroughconversion')
	// 	// Do not show dialog for handled errors
	// 	const isNotHandled =
	// 		event?.exception?.values[0]?.mechanism?.handled == 'no' ||
	// 		event?.exception?.values[0]?.mechanism?.handled == false
	// 	// Only show dialog under these conditions
	// 	const showDialog =
	// 		event.exception && !isChunkLoadError && isNotHandled && !isStripeSdkError && !isGooglePageAddError

	// 	// Check if we encountered an exception, and if so, show the report dialog
	// 	if (showDialog) {
	// 		// We can add data automatically to the report form here,
	// 		// as per : https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/user-feedback/
	// 		Sentry.showReportDialog({
	// 			eventId: event.event_id,
	// 			title: 'Something went wrong',
	// 			subtitle:
	// 				"We're here to help — tell us what happened below and then reach out to Customer Care for help by using our chat support in the bottom corner.",
	// 			subtitle2: '', // only shows up on Desktop view (not mobile)
	// 		})
	// 	}

	// 	return event
	// },
	// // This sets the REPLAYS sample rate to be 0% in dev, 1% in every other environment
	// replaysSessionSampleRate: process.env.NODE_ENV === 'development' ? 0 : 0,
	// // If the entire session is not sampled, use the below sample rate to sample
	// // sessions when an error occurs.
	// replaysOnErrorSampleRate: process.env.NODE_ENV === 'development' ? 0 : 1.0,
	// integrations: [
	// 	new Sentry.Replay(),
	// 	// DISABLED TRACING TEMPORARILY, UNTIL SENTRY HAS TREE SHAKING IN PLACE FOR THE SDK
	// 	// new Integrations.BrowserTracing(),
	// ],
	// DISABLED TRACING TEMPORARILY, UNTIL SENTRY HAS TREE SHAKING IN PLACE FOR THE SDK
	// tracesSampleRate: 0.01,
	ignoreErrors: [
		// Sentry bening error
		'ResizeObserver loop limit exceeded',
		'ResizeObserver loop completed with undelivered notifications',
		// Random plugins/extensions
		'Extension context invalidated.', // some weird chrom bug
		'top.GLOBALS',
		// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
		'originalCreateNotification',
		'canvas.contentDocument',
		'MyApp_RemoveAllHighlights',
		'http://tt.epicplay.com',
		"Can't find variable: ZiteReader",
		'jigsaw is not defined',
		'ComboSearch is not defined',
		'http://loading.retry.widdit.com/',
		'atomicFindClose',
		// Facebook borked
		'fb_xd_fragment',
		// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
		// reduce this. (thanks @acdha)
		// See http://stackoverflow.com/questions/4113268
		'bmi_SafeAddOnload',
		'EBCallBackMessageReceived',
		// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
		'conduitPage',
		// 'The string did not match the expected pattern.', // Safari dev issue
	],
	denyUrls: [
		// Facebook flakiness
		/graph\.facebook\.com/i,
		// Facebook blocked
		/connect\.facebook\.net\/en_US\/all\.js/i,
		// Woopra flakiness
		/eatdifferent\.com\.woopra-ns\.com/i,
		/static\.woopra\.com\/js\/woopra\.js/i,
		// Chrome extensions
		/extensions\//i,
		/^chrome:\/\//i,
		// Other plugins
		/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
		/webappstoolbarba\.texthelp\.com\//i,
		/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
		// Adwords pagead conversion flakiness
		/pagead\/viewthroughconversion/i,
	],
})

// ServiceWorker initialization
if ('serviceWorker' in navigator) {
	const wb = new Workbox('/serviceWorker.js')
	console.log('Starting New Service Worker Install')

	// Ensure the SW installs immediately
	wb.addEventListener('install', function (event) {
		console.log('SkipWaiting')
		event.waitUntil(self.skipWaiting())
	})

	// Ensure SW claims all active clients - all tabs
	wb.addEventListener('activate', function (event) {
		console.log('clientsClaim')
		event.waitUntil(clientsClaim())
	})

	wb.register()
		.then((registration) => {
			console.log('SW Registered: ', registration)
		})
		.catch((registrationError) => {
			console.error('SW registration failed: ', registrationError)
		})
}

function App() {
	React.useEffect(() => {
		const jssStyles = document.querySelector('#jss-server-side')
		if (jssStyles) {
			jssStyles.parentElement.removeChild(jssStyles)
		}
	}, [])

	//Determine whether user is on standalone(PWA) or browser - logs this to dataLayer
	useAppInstalledEvent()
	const helmetContext = {}

	return (
		<SocketProvider>
			<Provider store={store} key="provider">
				<GlobalSocketListeners>
					<CustomRouter history={history}>
						<HelmetProvider context={helmetContext}>
							<Routes>
								<Route path="*" element={<Root />} />
							</Routes>
						</HelmetProvider>
					</CustomRouter>
				</GlobalSocketListeners>
			</Provider>
		</SocketProvider>
	)
}

/* Hydrate Root component at target DOM element */
loadableReady(() => {
	const root = document.getElementById('root')
	ReactDOM.hydrate(<App />, root)
})
