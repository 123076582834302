import axios from 'axios'
import { reject } from './checkout/utils'
import config from '../../client/config.client'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: config.api.baseUrl,
})

/**
 * POST a RSVP for a given user
 * @param {*} data
 * @returns
 */
const postRSVP = async (data) => {
	const response = await instance.post('/v2/rsvps', data)
	return response
}

/**
 * PUT a RSVP  (by creator only)
 * @param {*} data
 * @returns
 */
const updateRSVP = async (data) => {
	const filteredData = reject(data, ['id'])
	const response = await instance.put('/v2/rsvps/' + data.id, filteredData)
	return response
}

/**
 * POST a RSVP invite for a given rsvpId
 * @param {*} data
 * @returns
 */
const postInvite = async (data) => {
	const response = await instance.post(`/v2/rsvps/${data.id}/invite`)
	return response
}

/**
 * POST an RSVP Reminder for a given rsvpId
 * @param {*} data
 * @returns
 */
const postReminder = async (data) => {
	const response = await instance.post(`/v2/rsvps/${data.id}/reminder`)
	return response
}

/**
 * POST a Published Email for a given event id
 * @param {*} data
 * @returns
 */
const postPublishedEmail = async (data) => {
	const response = await instance.post('/mail/published', data)
	return response
}

/**
 * POST verify email address
 * @param {*} email any email address
 * @returns
 */
const verifySingleEmail = async (data) => {
	const response = await instance.post('/v2/mail/verify', data)
	return response
}

/**
 * PUT change primary email address
 * @param {*} email any email address
 * @returns
 */
const updatePrimaryEmailAddress = async (data) => {
	const response = await instance.put(`/v2/users/${data.id}/change-email`, data)
	return response
}

const requests = {
	postRSVP,
	updateRSVP,
	// Messages
	postInvite,
	postReminder,
	postPublishedEmail,
	verifySingleEmail,
	//User
	updatePrimaryEmailAddress,
}

export default requests
