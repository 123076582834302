import { RSVP, MAIL, TEMPORARY_USER, AUTH, MEDIA, PUBLISHED_MEDIA } from './constants'

/** RSVP */
const postRSVP = (data) => ({
	type: RSVP.POST_REQUESTED,
	payload: data,
})

const updateRSVP = (data) => ({
	type: RSVP.UPDATE_REQUESTED,
	payload: data,
})

const postInvite = (data) => ({
	type: RSVP.POST_INVITE_REQUESTED,
	payload: data,
})

const postReminder = (data) => ({
	type: RSVP.POST_REMINDER_REQUESTED,
	payload: data,
})

const updateRSVPFilters = (data) => ({
	type: RSVP.SET_FILTER,
	payload: data,
})

/** MAIL */
const postPublishedEmail = (data) => ({
	type: MAIL.POST_PUBLISHED_REQUESTED,
	payload: data,
})

const verifySingleEmail = (data) => ({
	type: MAIL.POST_VERIFY_REQUESTED,
	payload: data,
})

/** USER */
const setTemporaryUser = (data) => ({
	type: TEMPORARY_USER.SET,
	payload: data,
})

const updatePrimaryEmailAddress = (data) => ({
	type: AUTH.PUT_CHANGE_PRIMARY_EMAIL_REQUESTED,
	payload: data,
})

export {
	// RSVP
	postRSVP,
	updateRSVP,
	updateRSVPFilters,
	// Messages
	postInvite,
	postReminder,
	postPublishedEmail,
	verifySingleEmail,
	// User
	setTemporaryUser,
	updatePrimaryEmailAddress,
}
