import React, { memo } from 'react'
import loadable from '@loadable/component'
import { Routes, Route, useParams, Navigate } from 'react-router-dom'

import { useEventContext } from '../contexts/EventProvider'
import { useAuthContext } from '../contexts/AuthProvider'
import PrivateSocketListeners from '../contexts/PrivateSocketListeners'
import FancyLoader from '../ui/atoms/FancyLoader'
import redirectToNextIfWindow from '../utils/redirectToNextIfWindow'

const Archived = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Archived'))
const ArchivedRestoring = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/ArchivedRestoring'))
const Publishing = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Publishing'), { ssr: false })
const Published = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Published'))
const Customize = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Customize'))
const SceneryMusic = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/SceneryMusic'))
const SceneryTheme = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/SceneryTheme'))
const SceneryThemeIndividual = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/SceneryThemeIndividual'))
const Preview = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Preview'))
const EditMedia = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/EditMedia'))
const GiftCollection = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/GiftCollection'))
const Checkout = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Checkout'))
const Paid = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Paid'))
const Marketplace = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Marketplace'))

const UploadCoverPhoto = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/UploadCoverPhoto'), {
	ssr: false,
})
const Event = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Event'), { ssr: false })
const UploadMedia = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/UploadMedia'), { ssr: false })
const UploadGiphy = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/UploadGiphy'), { ssr: false })
const UploadUnsplash = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/UploadUnsplash'), { ssr: false })
const UploadSong = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/UploadSong'), { ssr: false })
const AddTextCard = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/AddTextCard'), { ssr: false })
const Invitation = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Invitation'))
const EventDetails = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/EventDetails'))
const Keepsake = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Keepsake'))
const KeepsakeDVD = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Keepsake/DVD'), { ssr: false })
const KeepsakeUSB = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Keepsake/USB'), { ssr: false })
const KeepsakeVideoBook = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Keepsake/VideoBook'), {
	ssr: false,
})
const SendToRecipient = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/SendToRecipient'))
const SendToGeneral = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/SendToGeneral'))
const InviteList = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/InviteList'))
const Broadcast = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Broadcast'))
const Download = loadable(() => import(/* webpackPrefetch: true */ '../ui/pages/Download'))

const PrivateEventRouter = memo((props) => {
	return (
		<Routes>
			<Route index element={<RenderByStatus />} />
			<Route path="edit" element={<EventDetails />} />
			<Route path="keepsake/usb" element={<KeepsakeUSB />} />
			<Route path="keepsake/dvd" element={<KeepsakeDVD />} />
			<Route path="keepsake/video-book" element={<KeepsakeVideoBook />} />
			<Route path="keepsake" element={<Keepsake role="creator" />} />
			<Route path="cover/:type" element={<UploadCoverPhoto />} />
			<Route path="upload" element={<UploadMedia />} />
			<Route path="upload-giphy" element={<UploadGiphy />} />
			<Route path="upload-gallery" element={<UploadUnsplash />} />
			<Route path="upload-song" element={<UploadSong />} />
			<Route path="card" element={<AddTextCard />} />
			<Route path="invitation" element={<Invitation />} />
			<Route path="collect/*" element={<GiftCollection />} />
			<Route path="customize" element={<Customize />} />
			<Route path="music/*" element={<SceneryMusic />} />
			<Route path="theme/*" element={<SceneryTheme />} />
			<Route path="scenery/theme/:themeId" element={<SceneryThemeIndividual />} />
			<Route path="preview" element={<Preview />} />
			<Route path="checkout" element={<Checkout />} />
			<Route path="checkout/successful" element={<Paid />} />
			<Route path="finalize" element={<Checkout />} />
			<Route path="media/:mediaId" element={<EditMedia />} />
			<Route path="send/recipient" element={<SendToRecipient />} />
			<Route path="send/general" element={<SendToGeneral />} />
			<Route path="invite-list/*" element={<InviteList />} />
			<Route path="broadcast" element={<Broadcast />} />
			<Route path="add-ons" element={<Marketplace />} />
			<Route path="download" element={<Download />} />
		</Routes>
	)
})

/**
 * Retrieve the props of the Route, including the component to render.
 * Provide alternative view based on the event status.
 */
const RenderByStatus = () => {
	const { event } = useEventContext()
	const { role, isImpersonated } = useAuthContext()
	const status = event.isRestoring && event.status != 'published' ? 'restoring' : event.status

	if (['admin', 'editor'].includes(role) && !isImpersonated) {
		return <Event event={event} />
	}

	let View

	switch (status) {
		case 'active':
			View = <Event event={event} />
			break

		case 'abandoned':
		case 'archived':
			View = <Archived event={event} />
			break

		case 'restoring':
			View = <ArchivedRestoring event={event} />
			break

		case 'publishing':
			View = <Publishing />
			break

		case 'published':
			View = <Published />
			break

		default:
			View = <FancyLoader /> //<Loader size={120} fancy fullscreen />
	}

	return View
}

/**
 * Route Component to protect access to the Private Event Routes
 * This include all routes under /event/:uuid *
 * @returns
 */
const ProtectedEventRoute = () => {
	const { isAuthenticated, isAuthenticating, hasAdminPrivilege, isAdmin } = useAuthContext()

	/** Retrieve the event from the state */
	const { event, isOwner, isFetching } = useEventContext()

	const { uuid } = useParams()

	/**
	 * Because of the way the routes are nested,
	 * we can always expect to have an event
	 * and a UUID here available.
	 * */
	if (event && uuid) {
		/**
		 * If we're not fetching an event and the
		 * authed user is the owner of the event,
		 * renders our Event component.
		 */
		if (!isFetching) {
			if (isOwner || isAdmin) {
				return (
					<PrivateSocketListeners>
						<PrivateEventRouter />
					</PrivateSocketListeners>
				)
			}
			// else if (event.status == 'published') {
			// 	console.log('here')
			// 	return <PublicEventRouter />
			// }
			else {
				if (typeof window !== 'undefined') {
					redirectToNextIfWindow(`/e/${uuid}`)
				}
				/**
				 * Otherwise, redirect to the public event page.
				 */
				return <Navigate to={`/${uuid}`} replace />
			}
		}
	}

	return <FancyLoader />
}

export default ProtectedEventRoute
